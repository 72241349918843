import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.dina.statusOnPress,
    height: ({ size }) => size,
    width: ({ size }) => size,
    '&:hover $iconWrapper:before': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },

    '&$checked': {
      color: theme.palette.dina.statusOnPress,
    },
    '&$disabled': {
      color: theme.palette.dina.onSelected,
      opacity: '0.35',
    },
  },
  checked: {},
  disabled: {},
  uncheckedIcon: {
    fill: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  iconWrapper: {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: ({ size }) => size,
      height: ({ size }) => size,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      transform: 'translate3d(-50%, -50%, 0)',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  checkedIcon: {
    fill: theme.palette.dina.whiteHighEmphasis,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
}));

export default useStyles;
