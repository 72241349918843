import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export const ItemWrapper = styled('div', transientOptions)<{
  $transform?: string;
  $transition?: string;
}>`
  height: 36px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 4px;
  padding: 0px 4px;
  white-space: nowrap;
  ${({ $transform, $transition }) => css`
    transform: ${$transform};
    transition: ${$transition};
  `}
`;

export const DragHandleWrapper = styled('div')`
  display: flex;
  align-items: center;
  :hover {
    cursor: grab;
    path {
      fill-opacity: 1;
    }
  }
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
`;
