import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';

export type GridActionType = 'remove';
interface Props {
  onClick: (type: GridActionType) => void;
}

const iconStyles = css`
  cursor: pointer;
  width: 18px;
  height: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

const RemoveIcon = styled(DeleteIcon)`
  ${iconStyles}
`;

/**
 * Action buttons at the end of each grid item
 * @param onClick - callback for the action
 * @param mType - memberType, used for tooltip
 */
export default function BlockGridActions({ onClick }: Readonly<Props>) {
  return (
    <HStack gap="6px" height="100%">
      <Tooltip title="Remove">
        <RemoveIcon onClick={() => onClick('remove')} />
      </Tooltip>
    </HStack>
  );
}
