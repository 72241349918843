import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import TextButton from '../textButton';
import Wrapper from '../wrapper';
import VerticalDivider from '../verticalDivider';
import BlockButton from '../blockButton';
import { Section, Container } from './styled';

const { IMAGE, VIDEO, PHOTO_GALLERY } = elementTypes;

const cmsVariants = {
  IFRAME: 'iframe',
  BLOCKS: 'blocks',
};

const CmsToolbar = ({ platformStructure }) => {
  const { variant, sections, allowVideoInPhotogallery } = platformStructure;

  return (
    <Container>
      {variant === cmsVariants.IFRAME ? (
        <Wrapper showRightMargin>
          <TextButton icon={<VideoIcon />} label="Add Video" type={VIDEO} />
          <TextButton icon={<PhotoIcon />} label="Add Photo" type={IMAGE} />
        </Wrapper>
      ) : (
        sections.map((section) => (
          <Section key={section.name}>
            <Wrapper showRightMargin rightMarginWidth={10}>
              {section.blocks.map((block) => (
                <BlockButton
                  key={block}
                  type={block}
                  tooltipType={
                    block === PHOTO_GALLERY && allowVideoInPhotogallery ? 'GALLERY' : block
                  }
                />
              ))}
            </Wrapper>
            <VerticalDivider />
          </Section>
        ))
      )}
    </Container>
  );
};

CmsToolbar.propTypes = {
  /** platform structure for the block */
  platformStructure: PropTypes.shape({
    variant: PropTypes.string,
    section: PropTypes.arrayOf(PropTypes.object),
  }),
};

CmsToolbar.defaultProps = {
  platformStructure: {
    variant: 'iframe',
    sections: [],
  },
};

export default memo(CmsToolbar);
