import { ItemParams } from 'react-contexify';

import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';

import useOpenMember from '../useOpenMember';

import { ContextItem, MemberMenuProps } from './Base';

type PropsFromTriggerType = {
  propsFromTrigger?: MemberMenuProps;
};

const checkIfDisabled = ({ propsFromTrigger }: PropsFromTriggerType) => {
  return Boolean((propsFromTrigger?.member?.mType as string) === 'draft');
};

export function OpenMemberItem({ ...contextProps }) {
  const { openItem } = useOpenMember();

  const handleClickEvent = (data: ItemParams<MemberMenuProps>) => {
    const { member } = data.props ?? {};
    if (member) openItem(member);
  };

  const isDisabled = checkIfDisabled(contextProps);

  return (
    <ContextItem
      id="open"
      label="Open in tab"
      disabled={isDisabled}
      icon={<Open />}
      onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data)}
      {...contextProps}
    />
  );
}
