import { atom, useAtom } from 'jotai';

import { Mdf } from 'types/graphqlTypes';

const editMdfDefaults: EditMdf = {
  mdf: null,
  open: false,
};

interface EditMdf {
  mdf: Mdf | null;
  open: boolean;
  onOk?: (updatedMdf: Mdf) => Promise<void>;
  onCancel?: () => void;
}

const editMdfDialog = atom<EditMdf>({
  ...editMdfDefaults,
});

const editMdfOpen = atom(false);
const editMdfDialogAtom = atom<EditMdf, [EditMdf], void>(
  (get) => get(editMdfDialog),
  (get, set, val) => {
    set(editMdfDialog, val);
    const { open } = val;
    if (open) {
      set(editMdfOpen, true);
    }
  },
);

const openDialogAtom = atom<boolean, [boolean], void>(
  (get) => get(editMdfOpen),
  (get, set, val) => {
    set(editMdfOpen, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        set(editMdfDialog, { ...editMdfDefaults });
      }, 500);
    }
  },
);

export const useEditMdfDialog = () => useAtom(editMdfDialogAtom);
export const useEditMdfOpenDialog = () => useAtom(openDialogAtom);
