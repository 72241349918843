import { elementTypes } from 'components/editor/constants';
import { Transforms } from 'slate';
import insertParagraph from '../../paragraph/utils/insertParagraph';
import { isElementActive } from '../../../utils';

const { HORIZONTAL_RULE, PARAGRAPH } = elementTypes;

/**
 * Handles onKeyDown event on horizontal rule elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const onHorizontalRuleKeyDown = (editor, event) => {
  const isActive = isElementActive(editor, HORIZONTAL_RULE);

  if (isActive) {
    const { key } = event;
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';

    if (isEnter || isBackspace) event.preventDefault();

    if (isEnter) insertParagraph(editor);

    if (isBackspace) Transforms.setNodes(editor, { type: PARAGRAPH });
  }

  return editor;
};

export default onHorizontalRuleKeyDown;
