import { Editor } from 'slate';

import { CustomElement } from 'types/editor';

const getSelectedElement = (editor: Editor, { depth = 1 } = {}) => {
  if (!editor) return null;

  const { selection } = editor;

  if (!selection?.anchor) return null;

  const [selectedElement] = Editor.node(editor, selection, { depth });

  return selectedElement as CustomElement;
};

export default getSelectedElement;
