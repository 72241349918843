const saveIptcMediaOnContext = (medtopResult, config) => {
  // eslint-disable-next-line no-param-reassign
  config.iptcMediaTopics = [
    ...medtopResult.data.getMemberFromId.map(({ mRefId, mTitle }) => ({
      categoryId: mRefId,
      label: mTitle,
    })),
  ];
};

export default saveIptcMediaOnContext;
