import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Scrollbar from 'components/scrollbar/scrollbar';

type FormProps = {
  $selected?: boolean;
};

export const SideBarWrapper = styled('div')<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  overflow: auto;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled('div')<{ inline?: boolean }>`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-top: 1px solid
    ${({ theme, inline }) => (inline ? theme.palette.dina.dividerLight : 'transparent')};
  background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
`;

export const Actions = styled.div`
  display: flex;
`;

export const Form = styled.div<FormProps>`
  cursor: pointer;
  height: 32px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  .deleteIcon,
  .editIcon {
    transition: width 150ms;
    width: 0px;
    overflow: hidden;
  }
  &:hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
    .deleteIcon,
    .editIcon {
      width: 24px;
      :hover {
        path {
          fill: ${({ $selected, theme }) =>
            $selected ? theme.palette.dina.whiteHighEmphasis : theme.palette.dina.highEmphasis};
        }
      }
    }
  }
  ${({ $selected, theme }) =>
    $selected
      ? css`
          background: ${theme.palette.dina.onSelected};
          .deleteIcon,
          .editIcon {
            path {
              fill: ${theme.palette.dina.whiteMediumEmphasis};
            }
          }
        `
      : css`
          background: transparent;
          .deleteIcon,
          .editIcon {
            path {
              fill: ${theme.palette.dina.mediumEmphasis};
            }
          }
        `};
`;

export const SchemaTitle = styled('div')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpaceContactWrapper = styled.div`
  width: 100%;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .unassign-icn {
    margin-top: 2px;
  }
`;

// Fix when Scrollbar is typed. Ran into many problems trying to convert it.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MetadataWrapper = styled(Scrollbar as any)`
  margin: 0 10px;
  width: calc(100% - 20px) !important;
`;

export const FormsWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;
