import styled from '@emotion/styled';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const Root = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px 16px;
`;

export const Text = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionSmall}
  margin-bottom: 3px;
`;

export const Anchor = styled(Link)`
  text-decoration-line: none;
`;

export const IconWrapper = styled('div')`
  align-self: flex-end;
`;
