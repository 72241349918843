import React from 'react';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import actionTypes from './actionTypes';

const menuItems = [
  {
    action: actionTypes.CREATE_TEAM,
    label: 'Create new Team',
    icon: <AddIcon />,
  },
];

export default menuItems;
