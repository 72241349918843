import { css } from '@emotion/react';
import colors from './Theme';

export const body1 = css`
  font-family: Inter;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: ${colors.highEmphasis};
`;

export const body2 = css`
  font-family: Inter;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.highEmphasis};
`;

export const button = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.highEmphasis};
`;

export const storyTab = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: ${colors.mediumEmphasis};
`;

export const caption = css`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.mediumEmphasis};
`;

export const captionItalic = css`
  font-family: Inter;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.mediumEmphasis};
`;

export const captionMedium = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.mediumEmphasis};
`;

export const captionRegular = css`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.mediumEmphasis};
`;

export const captionSmall = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${colors.mediumEmphasis};
`;

export const captionSmallBold = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${colors.mediumEmphasis};
`;

export const captionSmallLink = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: ${colors.mediumEmphasis};
  text-decoration-line: underline;
`;

export const captionLink = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.highEmphasis};
  text-decoration-line: underline;
`;

export const chipLabel = css`
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 13px;
  font-weight: normal;
  font-family: Inter;
  font-style: normal;
  color: ${colors.mediumEmphasis};
`;

export const durationLabel = css`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${colors.highEmphasis};
`;

export const h4 = css`
  font-family: Inter;
  font-weight: 300;
  font-style: normal;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
`;

export const h5 = css`
  font-family: Inter;
  color: ${colors.highEmphasis};
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: 300;
  line-height: 28px;
`;

export const h6 = css`
  font-family: Inter;
  color: ${colors.highEmphasis};
  font-size: 20px;
  letter-spacing: 0.15px;
  font-weight: 500;
  line-height: 24px;
`;

export const h7 = css`
  font-family: Inter;
  color: ${colors.highEmphasis};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

export const instanceCardTitle = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${colors.highEmphasis};
`;

export const instanceHeaderTitle = css`
  font-family: Inter;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
`;

export const instanceFooterLabel = css`
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.mediumEmphasis};
  font-style: normal;
  font-weight: normal;
`;

export const inputLabel = css`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  line-height: 10px;
  font-size: 16px;
  letter-spacing: 0.15px;
`;

export const listItemGroupHeadersLabel = css`
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.mediumEmphasis};
  text-transform: uppercase;
`;

export const listItemLabel = css`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.highEmphasis};
`;

export const listItemLabelBold = css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
`;

export const listItemLabelItalic = css`
  font-family: Inter;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.highEmphasis};
`;

export const listItemLabelLink = css`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.highEmphasis};
  text-decoration-line: underline;
`;

export const listItemLabelMedium = css`
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.highEmphasis};
`;

export const itemLabelMedium = css`
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.mediumEmphasis};
`;

export const overline = css`
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${colors.mediumEmphasis};
`;

export const planned = css`
  font-family: Inter;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: ${colors.mediumEmphasis};
  text-transform: uppercase;
`;

export const storyCardAvatarText = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: ${colors.mediumEmphasis};
`;

export const storyCardUpdated = css`
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: ${colors.highEmphasis};
`;

export const subtitle1 = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${colors.mediumEmphasis};
`;

export const teamInfoType = css`
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  color: ${colors.highEmphasis};
`;

export const marketplaceHeader = css`
  font-family: Inter;
  font-style: normal;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  color: ${colors.highEmphasis};
`;

export const tab = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.mediumEmphasis};
`;

export const rundownHeaderTitle = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.whiteHighEmphasis};
`;

export const storyHeaderTitle = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.whiteHighEmphasis};
`;
