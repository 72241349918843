import isHotkey from 'is-hotkey';
import { elementTypes } from 'components/editor/constants';
import { Editor, Transforms } from 'slate';

/**
 * Handles onKeyDown event on quote elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const onQuoteKeyDown = (editor, event) => {
  const [match] = Editor.nodes(editor, {
    match: ({ type }) => type === elementTypes.QUOTE,
  });

  if (match && isHotkey('shift+enter')(event)) {
    event.preventDefault();
    Transforms.insertText(editor, '\n');
  }

  return editor;
};

export default onQuoteKeyDown;
