/* eslint-disable sort-imports */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { v4 as uuid } from 'uuid';

import { useGetAiPrompts } from 'api/config/useGetAiPrompts';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import { Column, HeaderCell } from 'components/editMdfDialog/components/EditFieldModel';
import { Alternative } from 'types/graphqlTypes';

import { useChangedPrompts } from '../../../../../atomsTs';

import { AiConfigRow } from './AiConfigRow';

const Root = styled('div')`
  width: 100%;
`;

const columns: Column[] = [
  {
    id: 'draghandle',
    name: '',
  },
  {
    id: 'label',
    name: 'Label',
  },
  {
    id: 'ai-prompt',
    name: 'AI prompt',
  },
  {
    id: 'deletebutton',
    name: '',
  },
];

export function AiConfig() {
  const { prompts, loading } = useGetAiPrompts();
  const [localPrompts, setLocalPrompts] = useState<Alternative[] | null>(null);
  const [, setChangedPrompts] = useChangedPrompts();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (localPrompts === null && !loading) {
      setLocalPrompts(prompts);
    }
  }, [prompts, loading]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (Array.isArray(localPrompts)) {
      const oldIndex = localPrompts.findIndex((item) => item.id === active.id);
      const newIndex = localPrompts.findIndex((item) => item.id === over?.id);
      const updatedOrder = arrayMove(localPrompts, oldIndex, newIndex);
      setLocalPrompts(updatedOrder);
      setChangedPrompts(updatedOrder);
    }
  };

  const onUpdateAlternative = (alt: Alternative) => {
    const newPrompts = [...(localPrompts ?? [])];
    const altToUpdateIdx = newPrompts.findIndex((a) => a.id === alt.id);
    if (altToUpdateIdx >= 0) {
      newPrompts.splice(altToUpdateIdx, 1, alt);
    }
    setLocalPrompts(newPrompts);
    setChangedPrompts(newPrompts);
  };

  const onDelete = (id: string) => {
    const newPrompts = [...(localPrompts ?? []).filter((a) => a.id !== id)];
    setLocalPrompts(newPrompts);
    setChangedPrompts(newPrompts);
  };

  const addPrompt = () => {
    const newPrompt: Alternative = {
      id: uuid(),
      label: 'User visible prompt',
      value: 'Value sent to AI',
    };
    const newPrompts = [...(localPrompts ?? []), newPrompt];
    setLocalPrompts(newPrompts);
    setChangedPrompts(newPrompts);
  };

  return (
    <Root>
      <Table aria-label="aiconfig-table">
        <TableHead key="aiconfig-table-head">
          <TableRow key="aiconfig-header">
            {columns.map((col) => (
              <HeaderCell key={col.id}>
                {col.id === 'draghandle' && (
                  <IconButton
                    title="Add prompt"
                    size={24}
                    iconSize={20}
                    onClick={addPrompt}
                    usage="text"
                  >
                    <Add />
                  </IconButton>
                )}
                {col.name}
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={localPrompts ?? []} strategy={verticalListSortingStrategy}>
              {localPrompts
                ? localPrompts.map((prompt) => (
                    <AiConfigRow
                      prompt={prompt}
                      key={prompt.id}
                      onUpdateAlternative={onUpdateAlternative}
                      onDelete={() => onDelete(prompt.id)}
                    />
                  ))
                : null}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>
    </Root>
  );
}
