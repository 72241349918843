import { Item, ItemParams } from 'react-contexify';
import styled from '@emotion/styled';

import { HStack } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

const IconWrapper = styled('div')`
  width: 24px;
  padding: 0 2px;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export interface MemberMenuProps {
  member: MemberType;
  close?: () => void;
}

interface Props {
  icon: React.ReactNode;
  label: string;
  id: string;
  onClick: (data: ItemParams<MemberMenuProps>) => void;
  disabled?: boolean;
}

export function ContextItem({
  icon,
  label,
  id,
  onClick,
  disabled,
  ...contextProps
}: Readonly<Props>) {
  const handleClickEvent = (data: ItemParams<MemberMenuProps>) => {
    onClick(data);
    if (data.props?.close) data.props.close();
  };

  return (
    <Item
      id={id}
      key={id}
      disabled={disabled}
      onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data)}
      {...contextProps}
    >
      <HStack justifyContent="start" gap="6px" alignItems="center">
        <IconWrapper>{icon}</IconWrapper>
        <div>{label}</div>
      </HStack>
    </Item>
  );
}
