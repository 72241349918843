import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  box: {},
  divider: {
    height: '24px',
  },
}));

export default useStyles;
