import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    backgroundColor: 'transparent',
    '&:hover': {
      background: 'none',
    },
  },
}));

export default useStyles;
