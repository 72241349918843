import getMark from './getMark';

/**
 * Determines if specified mark type is active or not
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the mark to check for
 * @returns {Boolean}
 */

const isMarkActive = (editor, type) => Boolean(getMark(editor, type));

export default isMarkActive;
