import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

import useGetGroupPolicy from 'api/useGetGroupPolicy';
import Dialog, { DialogDataModel } from 'components/dialogs/DialogBuilder';
import { Box } from 'layouts/box/Box';
import ScrollArea from 'lib/scrollArea';
import { MemberType } from 'types/graphqlTypes';

type GroupType = {
  value: string;
  label: string;
};

interface DefautlPermissions {
  read?: string[];
  write?: string[];
}

interface Permissions {
  read: string[];
  write: string[];
}
interface Props extends DefautlPermissions {
  open: boolean;
  fieldId?: string;
  setPermissions: (fieldId: string, permissions: Permissions) => void;
}

const defaults: Props = {
  open: false,
  setPermissions: () => null,
};

const permissionDialog = atom<Props>(defaults);
export const usePermissionDialog = () => useAtom(permissionDialog);

const processPermission = (
  results: { read: string[]; write: string[] },
  permissionType: 'read' | 'write',
  groupId: string,
  value: boolean,
) => {
  const idx = results[permissionType].findIndex((g) => g === groupId);
  if (value && idx < 0) {
    results[permissionType].push(groupId);
  } else if (!value && idx >= 0) {
    results[permissionType].splice(idx, 1);
  }
};

function EditPermissionDialog() {
  const [state, setState] = usePermissionDialog();
  const { groupPolicies } = useGetGroupPolicy();

  const groups: GroupType[] =
    groupPolicies?.map((policy: MemberType) => ({
      value: policy.mRefId as string,
      label: policy.mTitle as string,
    })) ?? [];

  const groupValues = groups.map((group) => group.value);

  const createTableRow = useCallback(
    (group: GroupType) => {
      return [
        {
          id: `${group.value}-title`,
          value: group.label,
        },
        {
          id: `${group.value}-read`,
          value: (
            <Dialog.Checkbox
              propertyName={`read%%${group.value}`}
              initialValue={state?.read ? state.read.includes(group.value) : true}
            />
          ),
        },
        {
          id: `${group.value}-write`,
          value: (
            <Dialog.Checkbox
              propertyName={`write%%${group.value}`}
              initialValue={state?.write ? state.write.includes(group.value) : true}
            />
          ),
        },
      ];
    },
    [state.read, state.write],
  );

  const tableRows = groups?.map((group) => ({
    id: group.value,
    cells: createTableRow(group),
  }));

  const onClose = useCallback(() => {
    setState(defaults);
  }, [setState]);

  const onConfirm = useCallback(
    (data?: DialogDataModel) => {
      if (data && state.fieldId) {
        const { read, write } = Object.entries(data).reduce(
          (results, [key, value]) => {
            const [type, groupId] = key.split('%%') as ['read' | 'write', string];
            processPermission(results, type, groupId, value as boolean);
            return results;
          },
          { read: [...(state?.read || groupValues)], write: [...(state?.write || groupValues)] },
        );

        state.setPermissions(state.fieldId, { read, write });
        onClose();
      }
    },
    [groupValues, onClose, state],
  );

  return (
    <Dialog open={state.open} onClose={onClose}>
      <Dialog.Header>{`Configure ${state?.fieldId}`}</Dialog.Header>
      <Dialog.Body>
        <ScrollArea>
          <Dialog.Group noMargin>
            <Dialog.Label>Manage Visibility</Dialog.Label>
            <Box maxHeight="50vh" width="100%" padding="0 0 8px 0" overflow="auto">
              <Dialog.Table headers={['Groups', 'Read', 'Write']} rows={tableRows} />
            </Box>
          </Dialog.Group>
        </ScrollArea>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Apply" onConfirm={onConfirm} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default EditPermissionDialog;
