import { useEffect, useMemo, useState } from 'react';
import {
  closestCenter,
  // eslint-disable-next-line sort-imports
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  // eslint-disable-next-line sort-imports
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Scrollbar from 'components/scrollbar';
import Tooltip from 'components/tooltip';
import { Alternative } from 'types/graphqlTypes';

import { ButtonContainer, Container, MultiChoiceWrapper, OptionWrapper, Remove } from '../styled';
import { createAlternative } from '../utils';

import { SortableItemWrapper } from './SortableItemWrapper';

export const StyledCheckbox = styled(Checkbox)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  alternatives: Alternative[];
  updateAlternatives: (alts: Alternative[]) => void;
}

interface SpecialAlternative extends Alternative {
  markAsComplete: boolean;
}

const produceStatusOptions = (alts: SpecialAlternative[]): Alternative[] => {
  return alts.map((specialAlt) => ({
    id: specialAlt.id,
    label: specialAlt.label,
    value: `${specialAlt.markAsComplete ? 'inactive' : 'active'}#${specialAlt.value}`,
  }));
};

const nonEditableOptions = ['created', 'closed'];

export function EditStatusAlternativesDialog({
  open,
  setOpen,
  alternatives,
  updateAlternatives,
}: Readonly<Props>) {
  const [options, setOptions] = useState<SpecialAlternative[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setOptions(
      alternatives.map((alt) => {
        return {
          ...alt,
          value: alt.value.split('#')[1],
          markAsComplete: alt.value.startsWith('inactive#'),
        };
      }),
    );
  }, [alternatives]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const oldIndex = options.findIndex((item) => item.id === active.id);
    const newIndex = options.findIndex((item) => item.id === over?.id);
    const updatedOrder = arrayMove(options, oldIndex, newIndex);
    setOptions(updatedOrder);
  };

  const confirmChanges = () => {
    updateAlternatives(produceStatusOptions(options));
    setOpen(false);
  };

  const removeAlternative = (alternative: SpecialAlternative) => {
    if (!nonEditableOptions.includes(alternative.value)) {
      setOptions([...options.filter((alt) => alt.id !== alternative.id)]);
    }
  };

  const addOption = () => {
    const newOption = createAlternative('value');
    setOptions([...options, { ...newOption, markAsComplete: false }]);
  };

  const handleOptionChange = (
    newValue: string | boolean,
    prop: 'label' | 'value' | 'markAsComplete',
    alternative: SpecialAlternative,
    index: number,
  ) => {
    const copy = [...options];
    const newAlt: SpecialAlternative = {
      ...alternative,
      [prop]: newValue,
    };
    copy.splice(index, 1, newAlt);
    setOptions([...copy]);
  };

  const duplicateValues = useMemo(() => {
    const fSet = new Set<string>();
    const duplicates: string[] = [];
    options.forEach((opt) => {
      if (fSet.has(opt.value)) {
        duplicates.push(opt.value);
      } else {
        fSet.add(opt.value);
      }
    });
    return duplicates;
  }, [options]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure options</Dialog.Header>
      <Dialog.Body>
        <Container>
          <ButtonContainer>
            <Button
              width={120}
              height={32}
              variant="outlined"
              usage="outlined"
              onClick={addOption}
              title="Add option"
            >
              <Add className="skipOverride" />
              Add option
            </Button>
          </ButtonContainer>
          <MultiChoiceWrapper>
            <Scrollbar
              valueChanged={undefined}
              closeToBottom={undefined}
              top={undefined}
              bottom={undefined}
              dark={undefined}
              showHorizontal={undefined}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={options} strategy={verticalListSortingStrategy}>
                  {options.map((alt, i) => (
                    <SortableItemWrapper key={alt.id} item={alt}>
                      <OptionWrapper>
                        <StyledTextField
                          variant="filled"
                          value={alt.label}
                          onChange={(ev) => handleOptionChange(ev.target.value, 'label', alt, i)}
                        />
                        <StyledTextField
                          name="value"
                          variant="filled"
                          value={alt.value}
                          disabled={nonEditableOptions.includes(alt.value)}
                          onChange={(ev) => handleOptionChange(ev.target.value, 'value', alt, i)}
                          error={Boolean(duplicateValues.includes(alt?.value))}
                        />
                        <Tooltip title="Mark task as completed when this status is set">
                          <StyledCheckbox
                            name="checkbox"
                            checked={alt.markAsComplete}
                            onChange={() =>
                              handleOptionChange(!alt.markAsComplete, 'markAsComplete', alt, i)
                            }
                            disabled={nonEditableOptions.includes(alt.value)}
                            color="primary"
                          />
                        </Tooltip>
                        <Remove
                          className="remove"
                          onClick={() => removeAlternative(alt)}
                          $disabled={nonEditableOptions.includes(alt.value)}
                        />
                        {duplicateValues.includes(alt?.value) && (
                          <FormControl error>
                            <FormHelperText id={alt?.value}>Duplicate Value</FormHelperText>
                          </FormControl>
                        )}
                      </OptionWrapper>
                    </SortableItemWrapper>
                  ))}
                </SortableContext>
              </DndContext>
            </Scrollbar>
          </MultiChoiceWrapper>
        </Container>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          title={duplicateValues.length > 0 ? 'Duplicate options detected' : ''}
          disabled={duplicateValues.length > 0}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
