/**
 *  Hook for getting a single feed item. Give provider and mId as input
 */
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_FEED_ITEM = gql`
  query GetFeedItem($input: GetFeedItemInput) {
    getFeedItem(input: $input) {
      provider
      mId
      version
      mPublishedAt
      section
      priority
      pubstatus
      ednotes
      newscodes
      mTitle
      byline
      mContent
      mDescription
      location
      embargoed
      href
      copyrightnotice
      usageterms
      infosource
      storyList
      language
      firstcreated
      versioncreated
      revision
      slugline
      assets {
        duration
        headline
        byline
        type
        renditions {
          href
          uri
          mimetype
          duration
          videoaspectratio
          format
          height
          width
          filename
          duration
          rendition
          sizeinbytes
          fileextension
          orientation
          previewUri
          thumbnailUri
          videoPreviewUri
        }
      }
    }
  }
`;

const useGetFeedItem = () => {
  const [getItem] = useLazyQuery(GET_FEED_ITEM, {
    fetchPolicy: 'cache-and-network',
  });

  const fetchFeedItem = useCallback(
    async (provider, mId) => {
      const result = await getItem({
        variables: {
          input: {
            provider,
            mId,
          },
        },
      });

      return result?.data?.getFeedItem;
    },
    [getItem],
  );

  return [fetchFeedItem];
};

export default useGetFeedItem;
