/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

import {
  ColorVariants,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
  Props,
  TextVariants,
} from 'types';

import { TextWrapper } from './styled';

type Colors = keyof ColorVariants;
type Variants = keyof TextVariants;

export type TextProps = {
  color?: Colors | 'highEmphasis';
  variant?: Variants | 'body1';
  truncate?: boolean;
};

type TextComponent = <C extends React.ElementType>(
  props: PolymorphicComponentPropsWithRef<C, TextProps>,
) => React.ReactElement | null;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Text: TextComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    { as, color, variant, truncate, children, ...restProps }: Props<C, TextProps>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as ?? 'span';

    return (
      <TextWrapper color={color} variant={variant} $truncate={Boolean(truncate)}>
        <Component {...restProps} ref={ref}>
          {children}
        </Component>
      </TextWrapper>
    );
  },
);

export default Text;
