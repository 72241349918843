import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './title-styles';

const TitleView = ({ children }) => {
  const classes = useStyles();

  return <Typography className={classes.root}>{children}</Typography>;
};

TitleView.propTypes = {
  /** Content to show as title */
  children: PropTypes.node,
};

TitleView.defaultProps = {
  children: null,
};

export default TitleView;
