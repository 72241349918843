import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { List as MuiList, MenuItem as MuiMenuItem, Paper as MuiPaper } from '@material-ui/core';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Input = styled('input')`
  ${({ theme }) => theme.typography.dina.captionRegular};
  background: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  :focus {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      border: 1px solid ${theme.palette.dina.onSelected};
    `}
  }
  :not(:focus)::selection {
    background: transparent;
    border: 1px solid transparent;
  }
  :not(:focus) {
    white-space: nowrap;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Paper = styled(MuiPaper)`
  position: absolute;
  width: fit-content%;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: ${({ background, theme }) => background || theme.palette.dina.surfaceDialogs};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;
