import gql from 'graphql-tag';

export default gql`
  mutation UpdateTeam($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarUrl
    }
  }
`;
