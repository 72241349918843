import { actionTypes } from 'components/editor/constants';
import primaryTypeValues from 'components/editor/constants/types/primaryTypeValues';
import { Editor } from 'slate';

/**
 * Handles onKeyDown event on primary automation elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const onPrimaryKeyDown = (editor, event, update) => {
  const [match] = Editor.nodes(editor, {
    match: ({ type }) => primaryTypeValues.includes(type),
  });

  if (match) {
    const { key } = event;
    const isBackspace = key === 'Backspace';

    if (isBackspace) {
      const [removedElement] = match;

      update({
        type: actionTypes.AUTOMATION_REMOVE,
        payload: { document: editor.children, removedElement },
      });
    }
  }

  return editor;
};

export default onPrimaryKeyDown;
