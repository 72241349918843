import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { initialValues } from 'components/editor/constants';
import configCtx from 'contexts/configContext';
import memberTypes from 'graphql/memberTypes';
import CREATE_INSTANCE from 'graphql/mutations/createInstance';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetRundown from 'hooks/useGetRundown';
import useSettingsValue from 'hooks/useSettingsValue';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import useStoryPanes from 'screens/storyV2/useStoryPanes';
import { useLoadingIndicator } from 'store';
import { useCurrentTabValue } from 'store/tabs';
import { Instance } from 'types';
import {
  AccountType,
  CreateInstanceInput,
  CreatePlatformInput,
  MemberType,
  PlatformStructure,
  Rundown,
} from 'types/graphqlTypes';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import getPlatformMetadata from 'utils/instance/getPlatformMetadata';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import { getTwitterMetaKey } from 'utils/metadata';
import useLogger from 'utils/useLogger';

import { getErrorMessage } from '../commonKeys';

interface CreateInstance {
  createInstance: MemberType;
}

interface PlatformData {
  platform: string;
  platformKind?: string;
  account: AccountType & {
    isUnassigned?: boolean;
  };
}

export const useCreateInstance = () => {
  const logger = useLogger('CreateInstance');
  const { navigateTo } = useDinaNavigate();
  const { metadataForms } = useContext(configCtx);
  const { updateStoryPane } = useStoryPanes();
  const form = metadataForms[0];
  const blankMetaData = getEmptyMetadataForForm(form);
  const { isWithinStoryScope } = useStoryMolecule();
  const { usePaneIndexValue, useNewlyAddedInstance, useCreatingInstance } = useStoryPaneMolecule();
  const paneIndex = usePaneIndexValue();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const [, setCreatingInstance] = useCreatingInstance();

  const currentTab = useCurrentTabValue();
  const { id: storyId = '', title, type: storyType } = currentTab || {};

  const [, setLoadingIndicator] = useLoadingIndicator();
  const [getRundown] = useGetRundown();
  const [getSettingsValue] = useSettingsValue();
  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed') as string;
  const [checkUserRight] = useCheckUserRight();

  const [createInstanceForStory] = useMutation<CreateInstance>(CREATE_INSTANCE, {
    update: (proxy, mutationResult) => {
      const createInstance = mutationResult.data?.createInstance;
      if (!createInstance) return;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateStoryInstanceCache(proxy, storyId, createInstance as Instance);
      if (mutationResult?.data?.createInstance?.mId) {
        if (isWithinStoryScope) {
          setNewlyAddedInstance(createInstance as Instance);
          updateStoryPane(paneIndex ?? 0, 'instances', createInstance.mRefId);
        } else {
          navigateTo('story', storyId, {
            tab: 'instances',
            entityId: mutationResult?.data?.createInstance?.mId,
          });
        }
      }
    },
  });

  const createNewInstance = useCallback(
    async (platformData: PlatformData, text?: string, platformInfo?: MemberType) => {
      const { platform, account, platformKind } = platformData;
      if (!platform || !account) return;

      const hasPermission = checkUserRight(
        'platform',
        getIdentifier(platform, account?.accountTitle || ''),
      );

      if (!hasPermission) return;
      if (isWithinStoryScope) {
        setCreatingInstance(true);
        updateStoryPane(paneIndex ?? 0, 'instances');
      }

      const platformProperties = {
        __typename: 'PlatformType',
        platform,
        account: {
          accountUrl: account.accountUrl,
          accountLogo: account.accountLogo,
          accountTitle: account.accountTitle,
          accountId: account.accountId,
        },
      } as CreatePlatformInput;

      if (platformKind) platformProperties.platformKind = platformKind;

      const input = {
        mId: storyId,
        mTitle: title,
        mDescription: 'some description',
        mProperties: platformProperties,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        mMetaData: getPlatformMetadata(platform, metadataForms, { title }),
      } as CreateInstanceInput;

      if (text) {
        const platformStructure = platformInfo?.mProperties?.platformStructure as PlatformStructure;
        const isCmsBlock =
          platformStructure && platform === 'cms' && platformStructure.variant === 'blocks';

        const mContent = JSON.stringify(initialValues(platform, true, isCmsBlock, text));
        input.mContent = mContent;
      }

      if (storyType === memberTypes.RESTRICTED_STORY) input.isRestricted = true;

      setLoadingIndicator('create');

      if (account && platform) {
        if (platform === 'linear') {
          if (account.isUnassigned) {
            await createInstanceForStory({
              variables: {
                input,
              },
            });
            setLoadingIndicator('');
            return;
          }

          await getRundown(account.accountId || '', account.accountId || '').then(
            async (rundown: Rundown | null) => {
              if (rundown) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                input.mProperties!.account!.accountRefId = rundown.mRefId;
                input.mPublishingAt = rundown.mPublishingAt;
                const hostMetaData =
                  rundown.mMetaData && rundown.mMetaData.find((val) => val.key === 'host')
                    ? rundown.mMetaData.find((val) => val.key === 'host')
                    : null;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const host =
                  hostMetaData && hostMetaData.value !== 'null'
                    ? JSON.parse(hostMetaData.value as string)
                    : null;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (host && host.mProperties && host.mProperties.readSpeed) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  input.mMetaData!.push({
                    key: 'hostReadSpeed',
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    value: host?.mProperties?.readSpeed,
                  });
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  input.mMetaData!.push({
                    key: 'hostReadSpeed',
                    value: defaultReadSpeed,
                  });
                }
              } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                input.mProperties!.account!.accountId = undefined;
              }

              await createInstanceForStory({
                variables: {
                  input,
                },
              });
              setLoadingIndicator('');
            },
          );
          return;
        }

        if (platform === 'twitter') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          input.mMetaData!.push({
            key: getTwitterMetaKey(blankMetaData),
            value: 1,
          });
        }

        await createInstanceForStory({
          variables: {
            input,
          },
        });
        setLoadingIndicator('');
      }
    },
    [
      checkUserRight,
      isWithinStoryScope,
      storyId,
      title,
      metadataForms,
      storyType,
      setLoadingIndicator,
      setCreatingInstance,
      updateStoryPane,
      paneIndex,
      createInstanceForStory,
      getRundown,
      defaultReadSpeed,
      blankMetaData,
    ],
  );

  const createInstance = useCallback(
    async (platformData?: PlatformData, text = '', platform?: MemberType) => {
      try {
        if (platformData && platform) await createNewInstance(platformData, text, platform);
      } catch (err) {
        logger.log(getErrorMessage(err, platformData));
      }
    },
    [createNewInstance, logger],
  );
  return { createInstance };
};
