/* eslint-disable max-len */
import styled from '@emotion/styled';
import { Close, Description, Provider, Root, Title, Viewport } from '@radix-ui/react-toast';

import { VStack } from 'layouts/box/Box';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const IconWrapper = styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  width: 42px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledViewPort = styled(Viewport)`
  --viewport-padding: 25px;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 350px;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

export const StyledContent = styled(VStack)`
  flex-grow: 1;
  text-align: center;
  padding: 0 6px;
  gap: 4px;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const StyledToastRoot = styled(Root)`
  ${dialogBoxShadow}
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  border-radius: 4px;
  display: flex;
  height: 100%;
  min-height: 60px;
  flex-direction: horizontal;

  &[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: hide 100ms ease-in;
  }
  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  &[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
  }

  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes swipeOut {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
  }
`;

export const StyledTitle = styled(Title)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const StyledDescription = styled(Description)`
  font-size: 11px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledClose = styled(Close)`
  flex: 0 0 60px;
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.storyCardHover};
  }
`;

export const StyledProvider = styled(Provider)``;
