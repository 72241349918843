import styled from '@emotion/styled';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 0 8px;
  margin-top: -1px;
`;

export const OuterWrapper = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
`;

export const InnerWrapper = styled('div')`
  display: flex;
  gap: 4px;
  padding-right: 4px;
`;

export const Empty = styled('div')`
  flex-grow: 1;
`;
