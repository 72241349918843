import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { addDays, subDays, startOfDay, endOfDay } from 'date-fns';
import { ReactComponent as ArrowLeftSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRightSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as TodayIconSrc } from 'assets/icons/systemicons/today.svg';
import { ClickAwayListener } from '@material-ui/core';
import Divider from 'components/divider';
import Calendar from 'components/calendar';
import Popper from 'components/shared/popper';
import Select from 'components/select';
import { RootWrapper, Navigator, ButtonWrapper, TypographyWrapper, DropDown } from './styled';
import {
  items,
  DROPDOWN_VALUES,
  outputTimeFormatForDate,
  outputTimeFormatForDateRange,
  moveDateRangeByOffset,
} from './utils';

const { ALL_TIME, SELECTED_DATES, OLDER, NEWER } = DROPDOWN_VALUES;

const getDropdownValue = (selectedDate) => {
  if (!selectedDate) return ALL_TIME;
  const { startDate, endDate } = selectedDate || {};
  if (startDate && endDate) return SELECTED_DATES;
  if (startDate === null && endDate === null) return ALL_TIME;
  if (endDate === null) return NEWER;
  return OLDER;
};

const DatePickerButton = ({
  selectedDate,
  onSelectedDateChange,
  enableMultiselect,
  canSelectRange,
  showQuickSelect,
  hideDropdown,
  disableDecrement,
  disableScheduleInPast,
}) => {
  const [selected, setSelected] = useState(selectedDate || new Date());
  const [selectedRange, setSelectedRange] = useState(
    selectedDate || { startDate: new Date(), endDate: new Date() },
  );

  const [nowShowing, setNowShowing] = useState('Today');

  useEffect(() => {
    if (typeof selectedDate === 'string' || selectedDate instanceof Date) {
      setNowShowing(outputTimeFormatForDate(selectedDate));
      return;
    }

    setNowShowing(outputTimeFormatForDateRange(selectedDate));
    const { startDate, endDate } = selectedDate || {};
    if (startDate && endDate) return;

    if (startDate === null && endDate === null) return setOn(false);

    const newSelectedDate = startDate || endDate || new Date();
    setSelected(newSelectedDate);
    setNowShowing(outputTimeFormatForDate(newSelectedDate));
  }, [selectedDate]);

  const [dropdownValue, setDropdownValue] = useState(
    enableMultiselect ? SELECTED_DATES : getDropdownValue(selectedDate),
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [on, setOn] = useState(true);

  const setPopoverAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const changeNowShowing = (newTime) => {
    setNowShowing(outputTimeFormatForDate(newTime));
  };

  const changeNowShowingForRange = ({ startDate, endDate }) => {
    setNowShowing(outputTimeFormatForDateRange({ startDate, endDate }));
  };

  const handleSelectedDateChange = useCallback(
    (newSelectedDate, newSelectedDateRange, newDropdownValue) => {
      switch (newDropdownValue) {
        case ALL_TIME:
          if (on) {
            onSelectedDateChange({ startDate: null, endDate: null });
            setNowShowing('All time');
            setOn(false);
          } else {
            onSelectedDateChange({ startDate: newSelectedDate, endDate: newSelectedDate });
            setDropdownValue(SELECTED_DATES);
            changeNowShowing(newSelectedDate);
            setOn(true);
          }
          break;
        case SELECTED_DATES:
          onSelectedDateChange({
            startDate: newSelectedDateRange.startDate,
            endDate: newSelectedDateRange.endDate,
          });
          setOn(true);
          break;
        case NEWER:
          onSelectedDateChange({ startDate: newSelectedDate, endDate: null });
          setOn(true);
          break;
        case OLDER:
        default:
          onSelectedDateChange({ startDate: null, endDate: newSelectedDate });
          setOn(true);
          break;
      }
    },
    [on],
  );

  const changeSelectedDate = useCallback(
    (newTime, newDropDownValue) => {
      const newSelectedDate = newTime.toISOString();
      const newSelectedDateRange = {
        startDate: startOfDay(newTime).toISOString(),
        endDate: endOfDay(newTime).toISOString(),
      };

      handleSelectedDateChange(
        newSelectedDate,
        newSelectedDateRange,
        newDropDownValue || dropdownValue,
      );

      setSelected(newSelectedDate);
      setSelectedRange(newSelectedDateRange);

      changeNowShowing(newTime);
      onClose();
    },
    [dropdownValue, handleSelectedDateChange],
  );

  const handleOnChangeDateRange = useCallback(
    ({ startDate, endDate }) => {
      const newDateRange = {
        startDate: startOfDay(startDate).toISOString(),
        endDate: endOfDay(endDate || startDate).toISOString(),
      };

      handleSelectedDateChange(
        endDate ? selected : startDate,
        newDateRange,
        endDate ? SELECTED_DATES : dropdownValue,
      );

      setSelectedRange(newDateRange);

      if (endDate) setDropdownValue(SELECTED_DATES);

      changeNowShowingForRange({ startDate, endDate: endDate || startDate });
      onClose();
    },
    [selected, handleSelectedDateChange, dropdownValue],
  );

  const handleIncrement = useCallback(() => {
    if (enableMultiselect || dropdownValue === SELECTED_DATES) {
      handleOnChangeDateRange(moveDateRangeByOffset(selectedRange));
    } else {
      changeSelectedDate(addDays(selected, 1));
    }
  }, [dropdownValue, handleOnChangeDateRange, selected, selectedRange]);

  const handleReset = useCallback(() => {
    changeSelectedDate(new Date());
    if (!on) {
      setDropdownValue(SELECTED_DATES);
      setOn(true);
    }
  }, [changeSelectedDate, on]);

  const handleDecrement = useCallback(() => {
    if (enableMultiselect || dropdownValue === SELECTED_DATES) {
      handleOnChangeDateRange(moveDateRangeByOffset(selectedRange, { backwards: true }));
    } else {
      changeSelectedDate(subDays(selected, 1));
    }
  }, [
    enableMultiselect,
    dropdownValue,
    selected,
    selectedRange,
    changeSelectedDate,
    handleOnChangeDateRange,
  ]);

  const onChangeDropdown = useCallback(
    (newDropdownValue) => {
      setDropdownValue(newDropdownValue);

      if (dropdownValue === SELECTED_DATES) {
        if (newDropdownValue === OLDER)
          return changeSelectedDate(endOfDay(selectedRange.endDate), newDropdownValue);

        if (newDropdownValue === NEWER)
          return changeSelectedDate(startOfDay(selectedRange.startDate), newDropdownValue);
      }

      handleSelectedDateChange(selected, selectedRange, newDropdownValue);
    },
    [dropdownValue, selected, selectedRange, changeSelectedDate, handleSelectedDateChange],
  );

  const memoizedCalendar = useMemo(
    () => (
      <Calendar
        hideUnscheduleButton
        showQuickSelect={showQuickSelect}
        selectedDateRange={selectedRange}
        changeSelectedDateRange={handleOnChangeDateRange}
        selectRange={canSelectRange}
        disableScheduleInPast={disableScheduleInPast}
        onClose={onClose}
      />
    ),
    [
      showQuickSelect,
      canSelectRange,
      selectedRange,
      handleOnChangeDateRange,
      disableScheduleInPast,
    ],
  );

  return (
    <>
      <RootWrapper>
        <Navigator>
          <ButtonWrapper
            onClick={handleDecrement}
            data-testid="decrement"
            disabled={disableDecrement || !on}
          >
            <ArrowLeftSrc />
          </ButtonWrapper>
          <ButtonWrapper onClick={handleReset} data-testid="reset">
            <TodayIconSrc title="Today" />
          </ButtonWrapper>
          <ButtonWrapper onClick={handleIncrement} data-testid="increment" disabled={!on}>
            <ArrowRightSrc />
          </ButtonWrapper>
          <TypographyWrapper onClick={setPopoverAnchor}>{nowShowing}</TypographyWrapper>
        </Navigator>
        {!hideDropdown && (
          <DropDown>
            <Select
              selectedValue={dropdownValue}
              items={items}
              onChange={onChangeDropdown}
              usage="datePicker"
              disabled={!on || enableMultiselect}
            />
          </DropDown>
        )}

        <Popper {...{ anchorEl }}>
          <ClickAwayListener onClickAway={onClose}>
            <div>{memoizedCalendar}</div>
          </ClickAwayListener>
        </Popper>
      </RootWrapper>
      <Divider />
    </>
  );
};

DatePickerButton.propTypes = {
  enableMultiselect: PropTypes.bool,
  onSelectedDateChange: PropTypes.func,
  hideDropdown: PropTypes.bool,
  disableScheduleInPast: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    }),
  ]),
  canSelectRange: PropTypes.bool,
  showQuickSelect: PropTypes.bool,
  disableDecrement: PropTypes.bool,
};

DatePickerButton.defaultProps = {
  enableMultiselect: false,
  onSelectedDateChange: () => {},
  hideDropdown: false,
  disableScheduleInPast: false,
  selectedDate: undefined,
  canSelectRange: false,
  showQuickSelect: false,
  disableDecrement: false,
};

export default DatePickerButton;
