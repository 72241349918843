/* eslint-disable no-param-reassign */
import inlineTypes from 'components/editor/constants/types/inlineTypes';

/**
 * Wraps editor with overriden inline plugin functionalites
 *
 * @param {Object} editor SlateJS editor instance
 * @returns {Object} SlateJS editor instance
 */

const withInline = editor => {
  const { isInline } = editor;

  editor.isInline = element =>
    Object.values(inlineTypes).includes(element.type) || isInline(element);

  return editor;
};

export default withInline;
