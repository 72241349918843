const textColors = [
  '#ffffff',
  '#ef5757',
  '#74db63',
  '#e459ce',
  '#89b6f9',
  '#ffbe55',
];

export default textColors;
