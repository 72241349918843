import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import Divider from 'components/divider';
import useStyles from './listItem-styles';

const ListItemView = ({ text, info, icon, topDivider, bottomDivider, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {topDivider && <Divider className={icon ? classes.iconDivider : classes.divider} />}
      <ListItem
        disabled={disabled}
        component="label"
        button
        classes={{ root: classes.listItem }}
        onClick={onClick}
      >
        {icon && <div className={classes.icon}>{icon}</div>}
        <ListItemText primary={text} classes={{ primary: classes.listItemText }} />

        {info && (
          <Typography classes={{ root: classes.infoText }} align="right">
            {info}
          </Typography>
        )}
      </ListItem>
      {bottomDivider && <Divider className={icon ? classes.iconDivider : classes.divider} />}
    </div>
  );
};

ListItemView.propTypes = {
  /** Primary text of the list item */
  text: PropTypes.string.isRequired,
  /** Determines if a top divider should be added */
  topDivider: PropTypes.bool,
  /** Determines if a bottom divider should be added */
  bottomDivider: PropTypes.bool,
  /** Returns a callback on Click */
  onClick: PropTypes.func,
  /** If the item is disabled */
  disabled: PropTypes.bool,
};

ListItemView.defaultProps = {
  topDivider: false,
  bottomDivider: false,
  onClick: () => {},
  disabled: false,
};

export default ListItemView;
