import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const toolbarButtonIds = {
  ASSETS: 'assets',
  READY: 'ready',
  PREPARING: 'preparing',
  EDITOR: 'editor',
};

export const assetsLocations = {
  LEFT: 'left',
  RIGHT: 'right',
};

const RND_DEFAULT_TOOLBAR_BUTTONS = [
  { id: toolbarButtonIds.ASSETS, selected: true },
  { id: toolbarButtonIds.READY, selected: true },
  { id: toolbarButtonIds.PREPARING, selected: true },
  { id: toolbarButtonIds.EDITOR, selected: true },
];

const RND_DEFAULT_STATE = {
  toolbarButtons: RND_DEFAULT_TOOLBAR_BUTTONS,
  editorSize: 'small',
  editorFontSize: 'small',
  assetsLocation: 'left',
};

const RND_STORAGE_KEY = 'rundown';

const savedRundownStates = JSON.parse(window.localStorage.getItem(RND_STORAGE_KEY));
const savedRundownToolbarButtons = savedRundownStates?.toolbarButtons || [];

const mergedToolbarButtons = RND_DEFAULT_TOOLBAR_BUTTONS.map(
  (toolbarButton) =>
    savedRundownToolbarButtons.find((button) => button.id === toolbarButton.id) || toolbarButton,
);
const mergedValue = {
  ...RND_DEFAULT_STATE,
  ...savedRundownStates,
  toolbarButtons: mergedToolbarButtons,
};

const rundownStorageAtom = atom(mergedValue);

const rundownAtom = atom(
  (get) => get(rundownStorageAtom),
  (_get, set, nextValue) => {
    set(rundownStorageAtom, nextValue);
    localStorage.setItem(RND_STORAGE_KEY, JSON.stringify(nextValue));
  },
);

export const useRundown = () => useAtom(rundownAtom);

const rundownToolbarButtonsAtom = atom(
  (get) => get(rundownAtom)?.toolbarButtons,
  (get, set, nextValue) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      toolbarButtons: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownToolbarButtons = () => useAtom(rundownToolbarButtonsAtom);

const rundownEditorSizeAtom = atom(
  (get) => get(rundownAtom)?.editorSize,
  (get, set, nextValue) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      editorSize: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownEditorSize = () => useAtom(rundownEditorSizeAtom);

const rundownEditorFontSizeAtom = atom(
  (get) => get(rundownAtom)?.editorFontSize,
  (get, set, nextValue) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      editorFontSize: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownEditorFontSize = () => useAtom(rundownEditorFontSizeAtom);

const rundownAssetsLocation = atom(
  (get) => get(rundownAtom)?.assetsLocation,
  (get, set, nextValue) => {
    const currentState = get(rundownAtom);
    const updatedState = { ...currentState, assetsLocation: nextValue };
    set(rundownAtom, updatedState);
  },
);

export const useRundownAssetsLocation = () => useAtom(rundownAssetsLocation);

const rundownGridViewAtom = atomWithStorage('currentRundownGridview', 'rundown-grid-1');
export const useRundownGridView = () => useAtom(rundownGridViewAtom);

const rundownGridViewListAtom = atom([]);
export const useRundownGridViewList = () => useAtom(rundownGridViewListAtom);
