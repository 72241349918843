import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const buttonStyles = {
    width: '136px',
    height: '40px',
    margin: '8px 8px 8px 0',
    borderRadius: '4px',
  };
  return {
    root: {
      width: '558px',
      height: '361px',
      backgroundColor: theme.palette.dina.surfaceCard,
      backdropFilter: 'blur(30px)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 8px 0 16px',
      paddingRight: '12px',
    },
    headerText: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      flexGrow: 1,
      marginLeft: '16px',
      maxHeight: '240px',
    },
    inputContainer: {
      margin: '0 16px 16px 0',
    },
    labelText: {
      ...theme.typography.dina.overline,
      lineHeight: '12px',
      letterSpacing: '1px',
      marginTop: '7px',
      width: '100%',
      height: '24px',
    },
    descriptionContainer: {
      marginRight: '16px',
      overflow: 'hidden',
    },
    descriptionInput: {
      height: '80px',
      overflow: 'hidden',
    },
    buttonContainer: {
      width: '100%',
      height: '56px',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    cancelButton: {
      ...buttonStyles,
      color: theme.palette.dina.mediumEmphasis,
    },
    createButton: {
      ...buttonStyles,
      backgroundColor: theme.palette.dina.statusApproved,
      '&:hover': {
        backgroundColor: theme.palette.dina.statusApproved,
      },
    },
    buttonLabel: {
      ...theme.typography.dina.button,
      fontWeight: '500px',
      textTransform: 'none',
    },
    warning: {
      margin: '-8px 0 -8px',
    },
    templateInput: {
      height: '40px',
    },
  };
});

export default useStyles;
