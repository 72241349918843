import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Header from './components/header';
import Info from './components/info';
import MobileConfig from './components/mobileConfig';
import Footer from './components/footer';
import useStyles from './appMenu-styles';

const AppMenu = ({ avatar, username, onLogout, onClose, setSettingOpen }) => {
  const classes = useStyles();
  const [showQR, setShowQR] = useState(false);
  return (
    <div className={classes.root}>
      <Header {...{ avatar, username, onClose, onLogout, showQR, setShowQR }} />
      <Divider className={classes.divider} />
      {showQR ? (
        <MobileConfig />
      ) : (
        <Info setShowQR={setShowQR} setSettingOpen={setSettingOpen} onClose={onClose} />
      )}
      <Divider className={classes.divider} />
      <Footer />
    </div>
  );
};

AppMenu.propTypes = {
  /** Name of user */
  username: PropTypes.string,
  /** Avatar url of user */
  avatar: PropTypes.string,
  /** on log out callback */
  onLogout: PropTypes.func,
  /** on close popover callback */
  onClose: PropTypes.func,
  /** on setSettingOpen callback */
  setSettingOpen: PropTypes.func,
};

AppMenu.defaultProps = {
  username: 'Lois Lane',
  avatar: '',
  onLogout: () => {},
  onClose: () => {},
  setSettingOpen: () => {},
};

export default AppMenu;
