import { Text, View } from '@react-pdf/renderer';

/**
 * When using <Text> component and the text is longer than 1 line, the hebrew text and arabic text are rendered wrong.
 * This component fixes that.
 */
export const RtlText = ({ children: text, ...props }) => {
  const { containerStyle: containerStyleProp, textAlign } = props;

  const sentences = text.split('\n');
  if (sentences.length > 1) {
    // each "sentence" doesn't have "\n"s in it
    return (
      <>
        {sentences.map((sentence, i) => (
          <RtlText key={i} {...props}>
            {sentence}
          </RtlText>
        ))}
      </>
    );
  }

  const words = text.split(' ');

  const TextStyle = { marginLeft: '2pt' };

  const ContainerStyle = Array.isArray(containerStyleProp)
    ? containerStyleProp
    : [containerStyleProp];
  ContainerStyle.push({ flexWrap: 'wrap', flexDirection: 'row-reverse' });

  if (textAlign === 'center') {
    ContainerStyle.push({ justifyContent: 'center' });
  }

  return (
    <View style={ContainerStyle}>
      {words.map((word, index) => (
        <Text style={TextStyle} key={index}>
          {word}
        </Text>
      ))}
    </View>
  );
};

export default RtlText;
