import styled from '@emotion/styled';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Section = styled('div')`
  height: 40px;
  display: flex;
`;
