import { makeStyles } from '@material-ui/styles';

const commonLabelStyles = (theme) => ({
  ...theme.typography.dina.inputLabel,
  color: theme.palette.dina.disabled,
  paddingLeft: '6px',
  '&$focused': {
    color: theme.palette.dina.disabled,
  },
  '&$disabled': {
    color: theme.palette.dina.disabled,
  },
  '&$error': {
    color: theme.palette.dina.disabled,
  },
});

const commonInputRootStyles = (theme) => ({
  minHeight: '48px',
  padding: 0,
  backgroundColor: theme.palette.dina.inputBackground,
  '&:hover:not($disabled)': {
    backgroundColor: theme.palette.dina.inputBackgroundFocused,
  },
  '&$focused': {
    backgroundColor: theme.palette.dina.inputBackgroundFocused,
  },
  '&$error': {
    border: `1px solid ${theme.palette.dina.statusError}`,
  },
});

const commonInputStyle = (theme) => ({
  ...theme.typography.dina.rightColumnHeaderText,
  color: theme.palette.dina.highEmphasis,
  padding: '15px 0px 0px 16px',
  '&:last-child': {
    paddingRight: '16px',
  },
});

const commonHelperTextStyle = (theme) => ({
  ...theme.typography.dina.caption,
  lineHeight: '14px',
  margin: 0,
  fontWeight: 'normal',
  padding: '4px 0px 5px 16px',
  borderBottomLeftRadius: '2px',
  borderBottomRightRadius: '2px',
  '&$error': {
    color: theme.palette.dina.mediumEmphasis,
    background: theme.palette.dina.statusError,
  },
  '&$disabled': {
    color: theme.palette.dina.disabled,
  },
});

const useStyles = makeStyles((theme) => ({
  inputFieldDiv: {
    width: 'auto',
  },
  labelRoot: {
    ...commonLabelStyles(theme),
  },

  filledLabel: {
    transform: 'translate(10px, 10px) scale(1)',
    '&$shrink': {
      transform: 'translate(10px, 4px) scale(0.70)',
    },
  },

  editorFilledLabel: {
    transform: 'translate(10px, 15px) scale(1)',
    '&$shrink': {
      transform: 'translate(10px, 4px) scale(0.70)',
    },
  },

  filledInputRoot: {
    ...commonInputRootStyles(theme),
  },
  filledInputRootMeta: {
    ...commonInputRootStyles(theme),
    height: '32px',
  },
  filledInputRootEditor: {
    ...commonInputRootStyles(theme),
    minHeight: '40px',
  },

  filledInput: {
    ...commonInputStyle(theme),
  },
  filledInputMeta: {
    ...theme.typography.dina.body2,
    lineHeight: '24px',
    fontWeight: 'normal',
    padding: '5px 5px 4px 12px',
  },
  filledInputEditor: {
    ...theme.typography.dina.body2,
    padding: '8px 8px 0px 14px',
  },
  filledInputEditorNoLabel: {
    ...theme.typography.dina.body2,
    padding: '8px',
  },

  cssUnderlineEditing: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.statusApproved}`,
    },

    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.statusApproved}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },

  cssUnderlineRegular: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },

    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },

  cssUnderlineTouched: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      /* previous: theme.palette.dina.onFocus */
      borderBottom: `2px solid ${theme.palette.dina.statusWarning}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.statusWarning}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },

  cssUnderlineNotTouched: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&$disabled:before': {
      borderBottomStyle: 'solid',
    },
  },

  adornmentRoot: {
    margin: 0,
  },

  iconButton: {
    color: theme.palette.dina.mediumEmphasis,
  },

  formHelperRootMeta: {
    ...commonHelperTextStyle(theme),
    padding: '4px 0px 5px 12px',
  },

  formHelperRoot: {
    ...commonHelperTextStyle(theme),
  },
  formHelperRootEditor: {
    ...commonHelperTextStyle(theme),
    padding: '2px 8px 5px 8px',
  },

  focused: {},
  disabled: {},
  error: {},
  shrink: {},
}));

export default useStyles;
