import { useState } from 'react';

/**
 * @typedef {Object} MenuAnchor
 * @property {HTMLElement} anchorEl - The element to which menu should be attached to
 * @property {Function} openMenu - Callback to be invoked to set anchorEl
 * @property {Function} closeMenu - Callback to be invoked to unset anchorEl to null
 * @property {Function} toggleMenu - Callback to be invoked to toggle anchorEl
 */

/**
 * Creates an anchor for Material UI Modal based components
 *
 * @returns {MenuAnchor}
 */
const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = ({ currentTarget }) => setAnchorEl(currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const toggleMenu = ({ currentTarget }) =>
    setAnchorEl(prevAnchorEl => (prevAnchorEl ? null : currentTarget));

  return { anchorEl, openMenu, closeMenu, toggleMenu };
};

export default useMenuAnchor;
