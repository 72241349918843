/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Mdf, MdfField, MdfFieldEntity, MdfType, UpdateMdfInput } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_MDF_KEYS } from '../commonKeys';

const CREATE_MDF = gql`
  mutation UpdateMdf($input: UpdateMdfInput) {
    updateMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface UpdateMdf {
  updateMdf: Mdf;
}

interface Input {
  input: UpdateMdfInput;
}

const toMdfFieldEntity = (field: MdfField): MdfFieldEntity => {
  return {
    ...field,
    defaultValue: JSON.stringify(field.defaultValue),
  };
};

const toMdfEntity = (mdf: Mdf): MdfType => {
  return {
    ...mdf,
    fields: mdf.fields.map(toMdfFieldEntity),
    permissions: {
      read: JSON.stringify(mdf.permissions.read),
      write: JSON.stringify(mdf.permissions.write),
    },
  };
};

export const useUpdateMdf = () => {
  const [updateMutation] = useMutation<UpdateMdf, Input>(CREATE_MDF);
  const logger = useLogger('UpdateMdf');
  const updateMdf = useCallback(
    async (mdfToSave: Mdf) => {
      try {
        const result = await updateMutation({
          variables: {
            input: toMdfEntity(mdfToSave),
          },
        });
        return result?.data?.updateMdf;
      } catch (err) {
        logger.log(getErrorMessage(err, mdfToSave));
      }
    },
    [updateMutation],
  );
  return { updateMdf };
};
