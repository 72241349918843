import styled from '@emotion/styled/macro';

import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as Reset } from 'assets/icons/systemicons/today.svg';
import { IconButton } from 'components/buttons';
import { HStack } from 'layouts/box/Box';

export const Button = styled(IconButton)`
  svg path {
    fill-opacity: 0.54;
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    svg path {
      fill-opacity: 1;
    }
  }
`;
interface ArrowNavigatorProps {
  buttonHeight?: number;
  buttonWidth?: number;
  resetTitle?: string;
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleReset: () => void;
  disabled?: boolean;
  dark?: boolean;
}

function ArrowNavigator(props: Readonly<ArrowNavigatorProps>) {
  const {
    buttonHeight = 32,
    buttonWidth = 32,
    resetTitle = '',
    handleDecrement,
    handleIncrement,
    handleReset,
    disabled = false,
    dark = false,
  } = props;

  return (
    <HStack overflow="visible">
      <Button
        height={buttonHeight}
        width={buttonWidth}
        onClick={handleDecrement}
        dataTestId="decrement"
        disabled={disabled}
        usage="contained"
      >
        <ArrowLeft className={dark ? 'skipOverride' : undefined} />
      </Button>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        onClick={handleReset}
        dataTestId="reset"
        disabled={disabled}
        title={resetTitle}
        usage="contained"
      >
        <Reset className={dark ? 'skipOverride' : undefined} />
      </Button>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        onClick={handleIncrement}
        dataTestId="increment"
        disabled={disabled}
        usage="contained"
      >
        <ArrowRight className={dark ? 'skipOverride' : undefined} />
      </Button>
    </HStack>
  );
}

export default ArrowNavigator;
