import React, { memo } from 'react';
import { elementTypes } from 'components/editor/constants';
import Wrapper from '../wrapper';
import IconButton from '../iconButton';
import BlockButton from '../blockButton';

const { IMAGE, VIDEO, GIF } = elementTypes;

const LinkedInToolbar = ({ extraBlocks }) => {
  return (
    <Wrapper showRightMargin>
      <IconButton type={VIDEO} />
      <IconButton type={IMAGE} />
      <IconButton type={GIF} />
      {extraBlocks?.map((block) => (
        <BlockButton key={block} type={block} tooltipType={block} />
      ))}
    </Wrapper>
  );
};

export default memo(LinkedInToolbar);
