import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

interface ButtonWrapperProps {
  disabled: boolean;
}

export const ButtonText = styled('span')`
  display: block;
  padding-top: 2px;
  color: ${({ theme }) => theme.palette.dina.whiteMediumEmphasis};
`;

export const ButtonWrapper = styled('div')<ButtonWrapperProps>`
  z-index: 50;
  margin: 0;
  ${({ disabled, theme }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          :hover {
            path {
              fill-opacity: 1;
            }
            ${ButtonText} {
              color: ${theme.palette.dina.whiteHighEmphasis};
            }
          }
        `}

  margin: 0;
  button {
    height: 100%;
    width: 60px;
    padding: 0 24px;
    font-size: 12px;
    border-radius: 0px;
    line-height: 13px;
  }
`;

export const MainWrapper = styled('div')`
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
`;
