import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
  divider: {
    height: '1px',
    width: '100%',
    margin: '4px 0px',
    background: theme.palette.dina.dividerLight,
  },
}));

export default useStyles;
