import gql from 'graphql-tag';

export default gql`
  query GetDepartmentMembers($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mAvatarUrl
    }
  }
`;
