import { memo } from 'react';

import { Root } from './styled';

interface WrapperProps {
  children: React.ReactNode;
  showRightMargin?: boolean;
  rightMarginWidth?: number;
}

function Wrapper({
  children,
  showRightMargin = false,
  rightMarginWidth = 4,
  ...rest
}: Readonly<WrapperProps>) {
  return (
    <Root $showRightMargin={showRightMargin} $rightMarginWidth={rightMarginWidth} {...rest}>
      {children}
    </Root>
  );
}

export default memo(Wrapper);
