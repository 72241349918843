import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  CreateMdfInput,
  DefaultValueWrapper,
  Mdf,
  MdfField,
  MdfFieldEntity,
  MdfType,
} from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { writeMdfToCache } from './handleMdfCache';

const CREATE_MDF = gql`
  mutation CreateMdf($input: CreateMdfInput) {
    createMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface CreateMdf {
  createMdf: MdfType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create mdf: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const toFieldMdfDto = (field: MdfFieldEntity): MdfField => {
  return {
    ...field,
    defaultValue: JSON.parse(field.defaultValue ?? '{"value": ""}') as DefaultValueWrapper,
  };
};

export const toMdfDto = (mdf: MdfType): Mdf => {
  const read = JSON.parse(mdf.permissions.read) as Record<string, string[]>;
  const write = JSON.parse(mdf.permissions.write) as Record<string, string[]>;

  return {
    ...mdf,
    permissions: {
      read,
      write,
    },
    fields: mdf.fields.map(toFieldMdfDto),
  };
};

export const useCreateMdf = () => {
  const [createMutation] = useMutation<CreateMdf>(CREATE_MDF);
  const logger = useLogger('CreateMdf');
  const createMdf = useCallback(
    async (input: CreateMdfInput) => {
      try {
        const result = await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newMdf = mutationResult.data?.createMdf;
            if (newMdf) {
              writeMdfToCache(proxy, newMdf);
            }
          },
        });
        return toMdfDto(result?.data?.createMdf as MdfType);
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation],
  );
  return { createMdf };
};
