import { useCallback, useContext } from 'react';

import { isOlderSlateValue, migrateValue } from 'components/editor/utils';
import UserContext from 'contexts/UserContext';
import { EditorValue } from 'types';
import { getUserLockToken } from 'utils/lock/lockToken';

import { useInstanceMolecule } from '../store/instance';

import useInstanceCore from './useInstanceCore';

const useInstanceVersion = () => {
  const { useInstanceValue } = useInstanceMolecule();
  const user = useContext(UserContext);

  const { resetEditorValue, saveAll, updateLock, handleLockInstance, lockedByCurrentUser } =
    useInstanceCore();

  const instance = useInstanceValue();

  const { mId: userId } = user;

  const checkVersionRestorability = useCallback(async () => {
    if (lockedByCurrentUser) return true;
    const lockedId = await handleLockInstance();
    return lockedId === getUserLockToken(userId);
  }, [handleLockInstance, lockedByCurrentUser, userId]);

  const onRestoreVersion = useCallback(
    async (rescont: EditorValue) => {
      if (!instance?.mId) return;

      const content = isOlderSlateValue(rescont)
        ? (migrateValue(rescont, {}) as EditorValue)
        : rescont;

      resetEditorValue(content);

      const params = {
        content,
        instance,
        unlock: true,
        version: 'restored',
        audit: { source: 'useInstanceVersion:onRestoreVersion' },
      };
      await saveAll(params);
      updateLock();
    },
    [resetEditorValue, instance, saveAll, updateLock],
  );

  return {
    onRestoreVersion,
    checkVersionRestorability,
  };
};

export default useInstanceVersion;
