import { Editor } from 'slate';

/**
 * Gets mark of specified type
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the mark
 * @returns {Boolean}
 */

const getMark = (editor, type) => {
  const marks = Editor.marks(editor);

  return marks && marks[type];
};

export default getMark;
