import React from 'react';
import PropTypes from 'prop-types';
import { AppBar as MaterialAppBar } from '@material-ui/core';
import useStyles from './app-bar-styles';

const AppBar = ({ children }) => {
  const classes = useStyles();

  return (
    <MaterialAppBar position="static" classes={{ root: classes.root }}>
      {children}
    </MaterialAppBar>
  );
};

PropTypes.propTypes = {
  /** Content to be rendered inside the app bar */
  children: PropTypes.node.isRequired,
};

export default AppBar;
