import { useEffect, useState } from 'react';

import Dialog from 'components/dialogs/DialogBuilder';
import { Box } from 'layouts/box/Box';
import { useEditMdfDialog, useEditMdfOpenDialog } from 'store/mdf-atoms';
import { Mdf } from 'types/graphqlTypes';

import { EditMdf } from './EditMdf';

import { Input } from './styled';

export function EditMdfDialog() {
  const [open, setOpen] = useEditMdfOpenDialog();
  const [mdfToSave, setMdfToSave] = useState<Mdf | null>(null);
  const [mTitle, setMTitle] = useState('');
  const [state] = useEditMdfDialog();

  const onConfirm = async (isCancel: boolean) => {
    if (isCancel && state.onCancel) {
      state.onCancel();
    } else if (!isCancel && mdfToSave) {
      const formToSave = { ...mdfToSave };
      formToSave.label = mTitle;
      if (state.onOk) {
        await state.onOk(formToSave).finally();
      }
    }

    setMdfToSave(null);
    setMTitle('');
    setOpen(false);
  };

  useEffect(() => {
    if (state.mdf) {
      setMTitle(state.mdf.label);
    }
  }, [state.mdf, setMTitle]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '75vw', maxWidth: '75vw' }}
    >
      <Dialog.Header>
        Editing form:
        <Input name="header-input" value={mTitle} onChange={(e) => setMTitle(e.target.value)} />
      </Dialog.Header>
      <Dialog.Body>
        <Box height="62vh">
          {state.mdf && <EditMdf mdf={state.mdf} onChange={(mdf) => setMdfToSave(mdf)} />}
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={() => void onConfirm(true)} />
        <Dialog.ConfirmButton label="Save" onConfirm={() => onConfirm(false)} />
      </Dialog.Footer>
    </Dialog>
  );
}
