import PropTypes from 'prop-types';
import { format as fnsFormat } from 'date-fns';

import TimeNavigator from 'components/timeNavigator';
import { HeaderWrapper, ComponentWrapper, TextWrapper, TimePickerComponentWrapper } from './styled';

const Header = ({ date, setDate, handleToggleDrawer }) => {
  const handleTimeNavigation = (newDate) => {
    setDate(newDate);
  };

  return (
    <HeaderWrapper>
      <ComponentWrapper>
        <TimeNavigator
          time={date}
          timeVariant='month'
          buttonHeight={48}
          onChange={handleTimeNavigation}
          resetTitle=''
        />
        <TimePickerComponentWrapper>
          <TextWrapper onClick={handleToggleDrawer}>{`${fnsFormat(
            date,
            'MMMM YYYY',
          )}`}</TextWrapper>
        </TimePickerComponentWrapper>
      </ComponentWrapper>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  setDate: PropTypes.func,
};

Header.defaultProps = {
  setDate: () => {},
};

export default Header;
