import styled from '@emotion/styled';

import { HStack } from 'layouts/box/Box';
import { CommandItem } from 'lib/command';

export const FeedDate = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
`;

export const FeedFooter = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  align-content: center;
  justify-content: start;
  gap: 4px;
`;

export const ItemInner = styled('div')`
  height: 100%;
  width: calc(100% - 32px);
  padding: 4px;
`;

export const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabelBold};
  margin-bottom: 4px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemDescription = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  position: relative;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrappedItem = styled(CommandItem)`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
  overflow: hidden;
  svg {
    width: 20px;
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    path {
      fill-opacity: 1;
    }
  }
  &.search-item,
  &.feed {
    height: 84px;
    .search-icon {
      align-self: start;
      margin-top: 4px;
    }
  }

  &.asset {
    height: 64px;
    svg {
      top: 1px !important;
    }
  }
`;
