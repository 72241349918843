/**
 * Gets the indent level from paragraph element data
 *
 * @param {Object} paragraphElement SlateJS 'paragraph' type element
 * @returns {Number} Current indent level
 */

const getIndentLevel = paragraphElement => {
  const { data = {} } = paragraphElement;
  const { indentLevel = 0 } = data;

  return indentLevel;
};

export default getIndentLevel;
