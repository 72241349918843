/* eslint-disable sort-imports */
import { Dictionary, groupBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { useOrderFormFilter } from 'store';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

interface MenuItem {
  label: string;
  id: string;
  children?: MenuItem[];
}

export const useGetOrderMenu = () => {
  const [formFilter] = useOrderFormFilter();
  const { orderForms, loading } = useGetOrderForms();

  if (loading) return { menuItems: [] };

  let formsByResourceTitle: Dictionary<OrderFormMemberType[]> | undefined = undefined;
  if (formFilter.types === null) {
    formsByResourceTitle = groupBy(orderForms, (orderForm) => orderForm.mTitle);
  } else {
    const filtered: OrderFormMemberType[] = [];
    for (const f of orderForms) {
      const typesConfig = f.configs.find((c) => c.key === 'types');
      if (typesConfig?.values?.some((t) => formFilter.types?.includes(t))) {
        filtered.push(f);
      }
    }

    formsByResourceTitle = groupBy(filtered, (orderForm) => orderForm.mTitle);
  }

  if (formsByResourceTitle) {
    const newMenuItems: MenuItem[] = [];
    for (const [space, forms] of Object.entries(formsByResourceTitle)) {
      newMenuItems.push({
        label: space,
        id: `SPACE#${space}`,
        // formRefId#mdfId
        children: forms.map((f) => ({ label: f.mDescription, id: `${f.mRefId}#${f.mSecId}` })),
      });
    }
    return { menuItems: newMenuItems };
  }

  return { menuItems: [] };
};
