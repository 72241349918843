import isHotkey from 'is-hotkey';
import { elementTypes } from 'components/editor/constants';
import { Editor, Transforms } from 'slate';
import isList from './isList';

const { LIST_ITEM, PARAGRAPH } = elementTypes;
const { nodes, levels, parent, isEmpty, previous } = Editor;
const { wrapNodes, unwrapNodes, setNodes } = Transforms;

/**
 * Handles onKeyDown event on list element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @param {Number} maxNestLevel Maximum allowed indentation level, default 2
 * @returns {Object} SlateJS editor instance
 */

const onListKeyDown = (editor, event, maxNestLevel = 2) => {
  const [match] = nodes(editor, {
    match: ({ type }) => type === LIST_ITEM,
  });

  if (match) {
    const isShiftTab = isHotkey('shift+tab')(event);
    const isTab = event.key === 'Tab';
    const isBackspace = event.key === 'Backspace';
    const [element, path] = match;

    if (isShiftTab || isTab) event.preventDefault();

    const depth = Array.from(levels(editor, { at: path })).length - 3;
    const isOnRootLevel = depth === 0;
    const [{ type }] = parent(editor, path);
    const previousNode = previous(editor, { at: path });

    if (isShiftTab) {
      unwrapNodes(editor, { match: isList, split: true });

      if (isOnRootLevel) setNodes(editor, { type: PARAGRAPH });
    }

    if (
      !isShiftTab &&
      isTab &&
      previousNode &&
      previousNode[0].type === LIST_ITEM &&
      depth < maxNestLevel
    ) {
      const block = { type, children: [] };
      wrapNodes(editor, block);
    }

    if (isBackspace && isEmpty(editor, element) && !previousNode && isOnRootLevel) {
      unwrapNodes(editor, { match: isList, split: true, mode: 'all' });
      setNodes(editor, { type: PARAGRAPH });
    }
  }

  return editor;
};

export default onListKeyDown;
