import React, { memo } from 'react';
import { elementTypes } from 'components/editor/constants';
import Wrapper from '../wrapper';
import BlockButton from '../blockButton';

const { GIF, IMAGE, VIDEO } = elementTypes;

const InstagramToolbar = ({ extraBlocks }) => {
  return (
    <Wrapper showRightMargin>
      <BlockButton type={VIDEO} data={{ showThumbnail: true }} />
      <BlockButton type={IMAGE} />
      <BlockButton type={GIF} />
      {extraBlocks?.map((block) => (
        <BlockButton key={block} type={block} tooltipType={block} />
      ))}
    </Wrapper>
  );
};

export default memo(InstagramToolbar);
