import React from 'react';
import InfoTab from './infoTab';

const TabContent = props => {
  const {
    contentType,
    teamTitle,
    teamId,
    teamDescription,
    containerRef,
    ...rest
  } = props;

  return (
    <InfoTab
      teamId={teamId}
      description={teamDescription}
      teamTitle={teamTitle}
      containerRef={containerRef}
      {...rest}
    />
  );
};

export default TabContent;
