import { elementTypes } from 'components/editor/constants/types';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

/**
 * Determines if given type is list type or not
 *
 * @param {String} type Type of the element node
 * @returns {Boolean}
 */

const isList = ({ type }) => [UNORDERED_LIST, ORDERED_LIST].includes(type);

export default isList;
