import { createContext } from 'react';

import { parseJSON } from 'utils/jsonUtils';
import { defaultSettings } from 'utils/settings/mergeSettings';

type AutomationTemplateConfigType = {
  automationTemplateConfigIndex: number;
  templateSetIndex: number;
};

const defaultAutomationTemplateConfigsValues = {
  automationTemplateConfigIndex: 0,
  templateSetIndex: 0,
};

const SavedAutomationTemplateConfigsValues = parseJSON(
  window.localStorage.getItem('automation'),
) as AutomationTemplateConfigType;

const automationTemplateConfigsValues = {
  ...defaultAutomationTemplateConfigsValues,
  ...SavedAutomationTemplateConfigsValues,
};

const ConfigContext = createContext({
  iptcMediaTopics: [],
  externalUrls: [],
  feeds: [],
  automationTemplateConfigs: [],
  automationTemplateConfigsValues,
  metadataForms: [],
  userRights: [],
  settings: [defaultSettings],
  featureFlags: [],
  kanbanBoardStates: [],
  kanbanBoardViews: [],
  groups: [],
});

export default ConfigContext;
