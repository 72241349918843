import React from 'react';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import menuActions from './menu-actions';

const menuItems = [
  {
    action: menuActions.CREATE_DEPARTMENT,
    label: 'Create new Department...',
    icon: <AddIcon />,
  },
];

export default menuItems;
