import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

interface DeleteBlock {
  deleteSingleMember: null;
}

const DELETE_SINGLE_MEMBER = gql`
  mutation DeleteBlock($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

const useDeleteBlock = () => {
  const [deleteSingleMember] = useMutation<DeleteBlock>(DELETE_SINGLE_MEMBER);

  const deleteBlock = useCallback(async (keys: { mId: string; mRefId: string }) => {
    await deleteSingleMember({
      variables: { input: keys },
    });
  }, []);

  return { deleteBlock };
};

export default useDeleteBlock;
